var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticStyle:{"padding-top":"0"},attrs:{"grid-list-xl":"","fluid":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"md12":""}},[_c('material-card',{attrs:{"title":_vm.$t('entity.storeChannelList'),"color":"green","flat":"","full-width":""}},[void 0,_c('v-data-table',{attrs:{"no-data-text":_vm.$t('common.noDataAvailable'),"headers":_vm.storeChannelHeaders,"items":_vm.storeChannelsList,"single-select":true,"hide-actions":""},scopedSlots:_vm._u([{key:"headerCell",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"text--darken-3 font-medium",domProps:{"textContent":_vm._s(_vm.$t(header.text))}})]}},{key:"items",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.stt))]),_c('td',[_vm._v(_vm._s(item.name))]),_c('td',[_vm._v(_vm._s(_vm.getChannelName(item.type)))]),(_vm.isAdmin())?_c('td',[_vm._v(_vm._s(item.id))]):_vm._e(),_c('td',{staticClass:"text-xs-center"},[_c('v-menu',{attrs:{"offset-y":"","transition":"slide-y-transition","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"indigo","dark":""}},on),[_c('v-icon',[_vm._v("mdi-dots-horizontal")])],1)]}}],null,true),model:{value:(item.selectedButton),callback:function ($$v) {_vm.$set(item, "selectedButton", $$v)},expression:"item.selectedButton"}},[_c('v-card',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",staticStyle:{"color":"#ffffff"},attrs:{"fab":"","small":"","color":"primary"},on:{"click":function($event){return _vm.onShowModalAddStoreChannel(item.id)}}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-square-edit-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('common.update')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",staticStyle:{"color":"#ffffff"},attrs:{"fab":"","small":"","color":"red"},on:{"click":function($event){return _vm.onShowModalConfirmDeleteCategory(item.id)}}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('common.delete')))])])],1)],1)],1)]}}])}),_c('pagination',{attrs:{"total":_vm.storeChannelPaginate.totalPage,"current-page":_vm.storeChannelPaginate.currentPage,"row-per-page":_vm.storeChannelPaginate.rowPerPage},on:{"onPageChange":_vm.onPageChange}})],2)],1)],1),_vm._l((_vm.GET_SNACK_BAR),function(snackbar){return _c('snack-bar',{key:snackbar.id,attrs:{"item":snackbar}})}),_c('confirmModal',{ref:"confirmModal",attrs:{"title":_vm.confirmModalTitle},on:{"onConfirm":_vm.onDeleteCategory}}),_c('addStoreChannelModal',{ref:"addStoreChannelModal",on:{"createStoreChannelSuccess":_vm.onCreateStoreChannelSuccess}}),_c('loadingBar',{attrs:{"is-loading":_vm.isLoading}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }
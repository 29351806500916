<template>
  <v-container
    grid-list-xl
    fluid
    style="padding-top: 0;">
    <v-layout wrap>
      <v-flex md12>
        <material-card
          :title="$t('entity.storeChannelList')"
          color="green"
          flat
          full-width
        >
          <template>
            <!-- <v-btn
              color="success"
              dark
              style="float: right;"
              @click="onShowModalAddStoreChannel(null)"
            >
              {{ $t('common.add') }}
            </v-btn> -->
          </template>
          <v-data-table
            :no-data-text="$t('common.noDataAvailable')"
            :headers="storeChannelHeaders"
            :items="storeChannelsList"
            :single-select="true"
            hide-actions
          >
            <template
              slot="headerCell"
              slot-scope="{ header }"
            >
              <span
                class="text--darken-3 font-medium"
                v-text="$t(header.text)"
              />
            </template>
            <template
              slot="items"
              slot-scope="{ item }"
            >
              <td>{{ item.stt }}</td>
              <td>{{ item.name }}</td>
              <td>{{ getChannelName(item.type) }}</td>
              <td v-if="isAdmin()">{{ item.id }}</td>
              <td class="text-xs-center">
                <v-menu
                  v-model="item.selectedButton"
                  offset-y
                  transition="slide-y-transition"
                  bottom
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      color="indigo"
                      dark
                      v-on="on"
                    >
                      <v-icon>mdi-dots-horizontal</v-icon>
                    </v-btn>
                  </template>
                  <v-card>
                    <!-- Update supplier -->
                    <v-tooltip
                      top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          class="mx-2"
                          fab
                          small
                          color="primary"
                          style="color: #ffffff;"
                          v-on="on"
                          @click="onShowModalAddStoreChannel(item.id)">
                          <v-icon dark>mdi-square-edit-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('common.update') }}</span>
                    </v-tooltip>
                    <!-- Delete supplier -->
                    <v-tooltip
                      top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          class="mx-2"
                          fab
                          small
                          color="red"
                          style="color: #ffffff;"
                          v-on="on"
                          @click="onShowModalConfirmDeleteCategory(item.id)">
                          <v-icon dark>mdi-delete</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('common.delete') }}</span>
                    </v-tooltip>
                  </v-card>
                </v-menu>
              </td>
            </template>
          </v-data-table>
          <pagination
            :total="storeChannelPaginate.totalPage"
            :current-page="storeChannelPaginate.currentPage"
            :row-per-page="storeChannelPaginate.rowPerPage"
            @onPageChange="onPageChange"
          />
        </material-card>
      </v-flex>
    </v-layout>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar" />
    <confirmModal
      ref="confirmModal"
      :title="confirmModalTitle"
      @onConfirm="onDeleteCategory"/>
    <addStoreChannelModal
      ref="addStoreChannelModal"
      @createStoreChannelSuccess="onCreateStoreChannelSuccess"/>
    <loadingBar :is-loading="isLoading" />
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ToastType from 'enum/toastType'
import ConfirmModal from 'Components/ConfirmModal'
import functionUtils from 'utils/functionUtils'
import AddStoreChannelModal from './Add.vue'
export default {
  components: {
    ConfirmModal,
    AddStoreChannelModal
  },
  data: () => ({
    storeChannelHeaders: [
      {
        sortable: false,
        text: 'common.nonumber',
        value: 'stt'
      },
      {
        sortable: false,
        text: 'common.name',
        value: 'name'
      },
      {
        sortable: false,
        text: 'Kênh',
        value: 'type'
      },
      {
        sortable: false,
        text: 'common.id',
        value: 'id'
      },
      {
        sortable: false,
        text: 'common.actions',
        value: 'actions',
        align: 'center'
      }
    ],
    storeChannelsList: [],
    storeChannelPaginate: {
      totalPage: 1,
      currentPage: 1,
      rowPerPage: 1
    },
    storeChannelId: null,
    confirmModalTitle: null,
    isLoading: false,
    valid: false
  }),
  computed: {
    ...mapGetters([
      'GET_SNACK_BAR',
      'GET_CURRENT_ACCOUNT'
    ])
  },
  watch: {
  },
  created () {
    this.getStoreChannels()
    if (!this.isAdmin()) {
      this.storeChannelHeaders = this.storeChannelHeaders.filter(element => {
        return element.value !== 'id'
      })
    }
  },
  methods: {
    /**
     * Get Role of Logged In User
     */
    isAdmin: function () {
      return functionUtils.isAdmin(this.GET_CURRENT_ACCOUNT)
    },
    /**
     * Get channel name
     */
    getChannelName: function (type) {
      return functionUtils.getDefaultStoreChannelName(type)
    },
    /**
     * Show modal add category
     */
    onShowModalAddStoreChannel: function (id) {
      this.$refs.addStoreChannelModal.onShowModal(id)
    },
    /**
     * Create category success
     */
    onCreateStoreChannelSuccess: function () {
      this.getStoreChannels()
    },
    /**
     * Delete domain
     */
    onDeleteCategory: function () {
      this.DELETE_STORE_CHANNEL({ id: this.storeChannelId }).then(
        function () {
          this.ON_SHOW_TOAST({
            message: this.$t('common.success'),
            styleType: ToastType.SUCCESS
          })
          this.getStoreChannels()
        }.bind(this)
      ).catch(
        function (error) {
          if (!functionUtils.isNull(error.response.data.errorText)) {
            let errorText = error.response.data.errorText
            this.ON_SHOW_TOAST({
              message: this.$t(errorText),
              styleType: ToastType.ERROR
            })
          } else {
            this.ON_SHOW_TOAST({
              message: this.$t('login.somethingWentWrong'),
              styleType: ToastType.ERROR
            })
          }
        }.bind(this)
      )
    },
    /**
     * Show modal confirm delete domain
     */
    onShowModalConfirmDeleteCategory: function (id) {
      this.storeChannelId = id
      this.confirmModalTitle = 'Bạn có chắc chắn muốn kênh bán hàng này?'
      this.$refs.confirmModal.onShowModal()
    },
    /**
     * Page change
     */
    onPageChange: function (page) {
      this.storeChannelPaginate.currentPage = page
      this.getStoreChannels()
    },
    /**
     * Get domain
     */
    getStoreChannels: function () {
      let filter = {
        params: {
          page: this.storeChannelPaginate.currentPage
        }
      }
      this.isLoading = true
      this.GET_STORE_CHANNELS_LIST(filter).then(
        function (res) {
          this.isLoading = false
          let data = res.data
          // Handle paginate
          this.storeChannelPaginate.currentPage = data.page
          this.storeChannelPaginate.totalPage = data.num_pages
          this.storeChannelPaginate.rowPerPage = data.per_page / 2
          this.storeChannelsList = []
          for (let i = 0, size = data.results.length; i < size; i++) {
            let storeChannelObj = {
              stt: i + 1 + (data.page - 1) * data.page_size,
              id: data.results[i].id,
              name: data.results[i].name,
              type: data.results[i].type
            }
            this.storeChannelsList.push(storeChannelObj)
          }
        }.bind(this)
      ).catch(
        function () {
          this.isLoading = false
        }.bind(this)
      )
    },
    ...mapActions([
      'ON_SHOW_TOAST',
      'GET_STORE_CHANNELS_LIST',
      'DELETE_STORE_CHANNEL'
    ])
  }
}
</script>

<style lang="scss" scoped>
.banner-img-class {
  width: 80%;
  cursor: pointer;
}
input[type="file"] {
  display: none;
}
.remove-banner-img {
  color: #ef5350;
  cursor: pointer;
}
.remove-banner-img:hover {
  text-decoration: underline;
}
/deep/.color-review {
  width: 100%;
  height: 305px;
  max-height: 305px;
  border: 1px solid #ddd;
  border-radius: 4px;
  caret-color: auto;
  &:hover {
    box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
  }
}
/deep/.required label::after {
  content: " *";
  color: red;
}
</style>
